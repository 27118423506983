.footer-container {
  background-color: white;
  color: #010D80;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  border-top: 1px solid #010D80;
  position: sticky;
}

.copyright,
.email {
  font-size: 18px;
  margin: 0;
}
.privacy-policy-link {
  text-decoration: none;
  color: inherit;
  margin: 0;
}

.footer-logo {
  max-width: 200px;
  height: auto;
}


@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center; 
    text-align: center;
    gap: 5px;
  }
  
  .footer-logo {
    margin-top: 10px; 
  }
}
