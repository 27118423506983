.header-container {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 5px solid #010D80;
  }
  
  .header-logo {
    max-width: 50%;
    height: auto;
  }
  
  .header-title {
    color: #333;
    margin-left: 20px;
  }
  