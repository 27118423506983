.countdown-container {
  text-align: center;
  min-height: 100vh;
}

.countdown-items {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  margin-top: 20px;
  gap: 40px;
}

.countdown-item {
  text-align: center;
  margin: 5px; 
}

.countdown-value {
  font-size: 36px;
  font-weight: bold;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #f00f2a;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.countdown-label {
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
}

.team-logo {
  max-width: 200px; 
  max-height: 200px;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}

.no-title {
  padding: 10px;
  border-radius: 5px ;
  color: #fff;
  font-size: 36px;
  text-align: center;
}
@media (max-width: 600px) {
  .countdown-items {
    flex-direction: column;
    align-items: center;
  }
}